import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

import { HeaderTriangle, ContactHeaderWrapper } from "./Header.styles"
import NavigationComponent from "../Navigation/Navigation.component"

export default props => {
  const data = useStaticQuery(graphql`
    query {
      youtubers: file(relativePath: { eq: "youtubers.png" }) {
        childImageSharp {
          fixed(height: 46) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      companies: file(relativePath: { eq: "companies.png" }) {
        childImageSharp {
          fixed(height: 46) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <ContactHeaderWrapper>
      <NavigationComponent />
      <div className="wrapper">
        <h1>Contact with Us</h1>
      </div>
      <HeaderTriangle className="hide-mobile">
        <div className="triangle" />
        <div className="triangle-content-wrapper">
          <div>
            <Img fixed={data.youtubers.childImageSharp.fixed} />
            For youtubers
          </div>
          <div>
            <Img fixed={data.companies.childImageSharp.fixed} />
            For companies
          </div>
        </div>
      </HeaderTriangle>
    </ContactHeaderWrapper>
  )
}
