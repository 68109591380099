import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { Formik, Field, ErrorMessage } from "formik"

import SEO from "../components/seo"
import ContactHeader from "../components/Header/ContactHeader"
import { ContactWrapper, StyledForm, SubmitButton } from "./_kontakt.styles"
import { Checkbox } from "antd"
import Axios from "axios"
import Swal from "sweetalert2"
import * as Yup from "yup"

const formSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  topic: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
  rules: Yup.mixed().oneOf([true], "Required"),
})

const ContactPage = () => {
  return (
    <Layout>
      <ContactHeader />
      <ContactWrapper>
        <div className="contact-info">
          <div className="items">
            {/* <div className="item phone">
              Phone
              <span>+48 111 111 111</span>
            </div> */}
            <div className="item email">
              Email
              <span>business@orlovage.com</span>
            </div>
          </div>
          <div className="socials">
            {/* <a className="social-item" href="https://facebook.com/">
              <img src={require("../images/facebook.svg")} alt="Facebook" />
            </a>
            <a className="social-item" href="https://instagram.com/">
              <img src={require("../images/instagram.svg")} alt="Instagram" />
            </a>
            <a className="social-item" href="https://youtube.com/">
              <img src={require("../images/youtube.svg")} alt="Youtube" />
            </a> */}
          </div>
        </div>
        <hr />
        <h3>Contact form</h3>
        <div className="form-wrapper">
          <Formik
            initialValues={{
              name: "",
              email: "",

              topic: "",
              message: "",
              rules: false,
            }}
            validationSchema={formSchema}
            onSubmit={(values, actions) => {
              Axios.post("https://formspree.io/mlepgogr", values)
                .then(response => {
                  actions.setSubmitting(false)
                  actions.resetForm()
                  Swal.fire({
                    title: "We've got it!",
                    text: "We will reply soon.",
                    timer: 2000,
                    icon: "success",
                  })
                })
                .catch(error => {
                  actions.setSubmitting(false)
                  Swal.fire({
                    title: "Something get wrong",
                    text: error.response.data.error,
                    timer: 2000,
                    icon: "error",
                  })
                })
            }}
          >
            {({ setFieldValue, submitForm }) => (
              <StyledForm>
                <div className="field-with-error">
                  <Field name="name" placeholder="Name" />
                  <div className="error">
                    <ErrorMessage name="name" />
                  </div>
                </div>
                <div className="field-with-error">
                  <Field name="email" placeholder="Email" />
                  <div className="error">
                    <ErrorMessage name="email" />
                  </div>
                </div>
                <div className="field-with-error topic">
                  <Field name="topic" className="topic" placeholder="Topic" />
                  <div className="error">
                    <ErrorMessage name="topic" />
                  </div>
                </div>
                <div className="field-with-error message">
                  <Field
                    as="textarea"
                    name="message"
                    className="message"
                    placeholder="Your message"
                    rows="8"
                  />
                  <div className="error">
                    <ErrorMessage name="message" />
                  </div>
                </div>

                <Field className="rules" name="rules">
                  {({ field }) => (
                    <Checkbox
                      className="rules"
                      {...field}
                      type="checkbox"
                      checked={field.value}
                      onChange={() => setFieldValue(field.name, !field.value)}
                    >
                      I understand that the information provided by me is
                      subject to the <Link to="/privacy">Privacy Policy</Link>.
                      <span className="error">
                        <ErrorMessage name="rules" />
                      </span>
                    </Checkbox>
                  )}
                </Field>

                <div className="buttons">
                  <SubmitButton onClick={submitForm}>SEND MESSAGE</SubmitButton>
                </div>
              </StyledForm>
            )}
          </Formik>
        </div>
      </ContactWrapper>
      <SEO title="Contact | Orlovage - backbone of influencers face of the brands" />
    </Layout>
  )
}

export default ContactPage
